import {assessments as config} from '../../data/config.coffee'
import CommonHelpers from './mixins/CommonHelpers.coffee'

window.mmog = window.mmog or {}

export default
	mixins: [CommonHelpers]
	props:
		assessmentInfos: Object
		compare: Boolean
	data: ->
		isDevelop: @$store.state.isDevelop
# use pending as flag for disabling buttons and for showing browsers default unsaved changes dialog or custom
		pendingMethods: {
			'assessmentWorkflowLink_finish': false,
			'assessmentWorkflowLink_start_survey': false,
			'assessmentWorkflowLink_start_review': false,
			'assessmentWorkflowLink_archive': false,
			'assessmentWorkflowLink_unarchive': false,
			'exchangeAssessment': false,
			'downloadAssessment': false,
		}
		spinnerLineFgColor: '#00725e'
		spinnerLineBgColor: '#c7dcd7'
		spinnerSize: 15
	computed:
		supplierPerspective: -> @assessmentInfos.partner.role is 'customer'
		pending: -> Object.values(@pendingMethods).reduce (x, y) -> x or y
	mounted: ->
		$(window).on 'turbolinks:before-visit.mmog', () =>
			if $('form.dirty').length > 0 and not @pending
				if (not @checkUnsavedChanges())
					return false
			return true

		$(window).on 'beforeunload.mmog', (e) =>
# Why set e.returnValue ? => see https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload#Examples
			return e.returnValue = '' if $('form.dirty').length > 0 and not @pending # pending true => confirm dialog was shown

	watch:
		'assessmentInfos.abilities': (abilities) ->
			for name, permitted of abilities
				$a = $("#main-menu a[data-vue-menu-action][data-vue-menu-event=#{name}]")
				$a.toggleClass 'disabled', not permitted
	methods:
		assessmentWorkflowLink: (event) ->
			@pendingMethods['assessmentWorkflowLink_' + event] = true
			response = await @$http.post(@pathFromAction 'workflow/' + event).catch (r) -> r.response
			@updateAssessmentInfos response?.data
			@pendingMethods['assessmentWorkflowLink_' + event] = false
		archiveAssessment: ->
			if confirm @$t('gon.archive_warning')
				@assessmentWorkflowLink('archive')
		unarchiveAssessment: ->
			@assessmentWorkflowLink('unarchive')
		exchangeAssessment: ->
			if @checkUnsavedChanges @$t('gon.dirty_exchange_warning')
				@pendingMethods['exchangeAssessment'] = true
				response = await @$http.post(@pathFromAction 'exchange').catch (r) -> r.response
				@updateAssessmentInfos response?.data
				@pendingMethods['exchangeAssessment'] = false
		downloadAssessment: ->
			if @checkUnsavedChanges @$t('gon.dirty_download_warning')
				@pendingMethods['downloadAssessment'] = true
				await @$http.get(@pathFromAction 'download').then (response) =>
					await @refreshAssessment()
					blob = new Blob([response.data], {type: 'application/xml'})
					filename = (response.headers['content-disposition'] or '').split('filename="')[1].split('";')[0]
					link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = filename
					link.click()
				@pendingMethods['downloadAssessment'] = false
		resetFilters: ->
			@$emit 'reset-filters'
		refreshAssessment: ->
			response = await @$http.get(@pathFromAction 'refresh.json').catch (r) -> r.response
			@updateAssessmentInfos response?.data
		refreshAssessmentInfobox: ->
			@$http.get(@pathFromAction 'refresh.js')
				.then (r) -> eval(r.data)
		updateAssessmentInfos: (response) ->
			@updateObject @assessmentInfos, response
			@refreshAssessmentInfobox()
		pathFromAction: (action) ->
			config.endpoint + @assessmentInfos.id + '/' + action
		checkUnsavedChanges: (message = @$t('gon.dirty_exit_warning')) ->
# show confirm dialog if there is any dirty form, return true if user confirms
			$('form.dirty').length is 0 or confirm message
		printAssessment: ->
			window.print()

	created: ->
		window.mmog.vueAssessmentMenuClick = (action, event) =>
			this[action].call this, event

	unmounted: ->
		$(window).off 'turbolinks:before-visit.mmog'
		$(window).off 'beforeunload.mmog'
		delete window.mmog.vueAssessmentMenuClick

