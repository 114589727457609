export default {
	props: ['val', 'identifier', 'maxCriteria', 'achievableScore']
	computed:
		max: ->
			if @identifier is 'point_score'
				@achievableScore
			else
				@maxCriteria
		title: ->
			@$t('assessments.progress_widget.metric.' + @identifier, {count: @val, max: @max})
}
