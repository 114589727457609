import {assessments as config} from '../../../data/config.coffee'
import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import {ListLoader} from 'vue-content-loader'
export default
	components: { ListLoader, Multiselect}
	props:
		sourceAssessment: Object
		isResetCompare: type: Boolean, default: false
	data: ->
		selectedAssessment: null
		isLoading: false
		loadingError: {}
		foundAssessments: []
		isResultOverflow: false
	watch:
		isResetCompare: (val) -> @resetCompare() if val
	methods:
		resetCompare: ->
			@foundAssessments = []
			@selectedAssessment = null
			@isResultOverflow = false
		debounceSearch:
			_.debounce(( (keyword)->
				@isLoading = true
				@searchAssessments(keyword)
				@isLoading = false
				return
			), 1000)

		onSelect: (selected) ->
			if (selected)
				@isResultOverflow = false
				url = config.endpoint + selected.id
				await @asyncGet url, null, (response) =>
					@$emit('update-target', response.data)
		searchAssessments: (searchText) ->
			return if searchText.length is 0

			search_query = 'q[title_or_partner_manufacturing_site_or_partner_partner_references_manufacturing_site_cont]'
			include_same_catalog = 'q[catalog_id_eq]'
			exclude_own_assessment = 'q[id_not_eq]'
			url = config.endpoint
			params = {
				"#{search_query}": searchText,
				"#{include_same_catalog}": @sourceAssessment.profile.catalog_id,
				"#{exclude_own_assessment}": @sourceAssessment.id
			}

			configRequest = {
				Accept: 'application/json',
				headers: { 'content-type': 'application/json' },
				dataType: 'json'
			}

			await @asyncGet url, { params, configRequest }, (response) =>
				@foundAssessments = response.data.items
				@isResultOverflow = response.data.items.length < response.data.total_count

		asyncGet: (url, param, response) ->
			@$http.get(url, param)
				.then response
				.catch (error) -> @loadingError = error.response?.data?.flash
