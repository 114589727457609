export default
	data: ->
		metrics: null
	methods:
		updateMetrics: (answers, criteria, hiddenCriteria) ->
			return unless answers or criteria
			visibleCriteria = criteria.filter((c) -> not hiddenCriteria[c.id])
			visibleAnswers = answers.filter((a) -> not hiddenCriteria[a.criterion_id])
			@metrics =
				count_criteria: visibleCriteria.length
				achievable_score:
					visibleCriteria
						.reduce (count, criterion) ->
							count + criterion.weight
						, 0
				values:
					fully_addressed: visibleAnswers.filter((a) -> a.valid).length
					non_scoring: visibleCriteria.length - visibleAnswers.filter((a) => @counts_for_score_as_compliant(a)).length
					rejected: visibleAnswers.filter((a) -> a.approved is false).length
					point_score: visibleAnswers
						.reduce (score, answer) =>
							score + @score_points answer, visibleCriteria.find((c) -> c.id is answer.criterion_id).weight
						, 0

		score_points: (answer, criterionWeight) ->
			if @counts_for_score_as_compliant(answer) then criterionWeight else 0

		counts_for_score_as_compliant: (answer) ->
			answer.compliance is 'yes' or @isAnswerNAApproved answer

		isAnswerNAApproved: (answer) ->
			answer.compliance is 'NA' and not @isNAApprovalMissing answer

		isNAApprovalMissing: (answer) ->
			not (answer.approval_date or answer.customer_contact)
