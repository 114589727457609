import Datepicker from 'vue3-datepicker'
import moment from 'moment'
import { enUS, de } from 'date-fns/locale'
import { format } from 'date-fns'

export default
	components: { Datepicker }
	props:
		modelValue: default: ''
		elementName: type: String, default: ''
		error: type: Array
		labelName: type: String, default: ''
		labelClass: type: Object
		datePickerClass: type: Object
		canEditAnswers: type: Boolean, default: true
	computed:
		_labelName: -> @labelName or @$t('activerecord.attributes.answer.' + @elementName)
		_labelClass: -> @labelClass or { 'label-error': @error }
		_datePickerClass: -> @datePickerClass or { 'input-error': @error }
		locales: -> { en: enUS, de: de }
		locale: -> @locales[moment.locale()]
	data: ->
		date: null
		format: 'dd MMM yyyy'
	watch:
		date: -> @$emit('update:modelValue', if !!@date then format(@date, 'yyyy-MM-dd') else null)
	mounted: ->
		if !!@modelValue
			@date = moment(@modelValue).toDate()
