import MmogRequiredAbbr from './MmogRequiredAbbr.vue'

export default
	components: { MmogRequiredAbbr }
	props:
		groupLabel: type: String, default: 'NO GROUP LABEL'
		choices: type: Array, default: []
		modelValue: type: String, default: ''
		error: type: Array
		required: type: Boolean
		labelClass: type: Object
		inputClass: type: Object
	data: ->
		value: @modelValue?.toString()
	computed:
		_labelClass: -> @labelClass or { 'label-error': @error }
		_inputClass: -> @inputClass or { 'input-error': @error }
