export default
	props:
		compareVisibility: Object
	data: ->
		value: 'all'
	watch:
		compareVisibility: ->
			@value = if @compareVisibility.all then 'all' else 'diff_only'
	methods:
		setFilterAll: (value) ->
			@$emit('compare-all', value)
