import Criterion from './Criterion.vue'

export default
	components: { Criterion }
	props:
		assessment: Object
		targetAnswers: Object
		filters: Object
		visibleCriteria: Array
		hiddenChapters: Array
		hiddenSubchapters: Array
		hiddenRequirements: Array
		targetExists: Boolean
		isCompareMode: Boolean
	computed:
		tocMode: ->
			if not @isCompareMode
				@filters.hierarchies.tableOfContent
			else
				@filters.compareHierarchy.tableOfContent
		isFilterCriteriaOnly: ->
			if not @isCompareMode
				@filters.hierarchies.criteriaOnly
			else
				@filters.compareHierarchy.criteriaOnly

	methods:
		toggleChapter: (chapter) ->
			@filters.chapter = chapter.id
			@filters.subchapter = null
		toggleSubchapter: (subchapter) ->
			@filters.subchapter = subchapter.id
		requirementTitleFields: (requirement) ->
			result = requirement.description?.match(/([^\:]*)\:\s?([\s\S]*)/)
			if result?.length is 3
				[global, head, tail] = result
				return { head, tail }
			else
				return { head: requirement.description, tail: '' }
		goToContent: (anchorId) ->
			if @isCompareMode
				@filters.compareHierarchy.tableOfContent = off
			else
				@filters.hierarchies.tableOfContent = off
			@$nextTick ->
				element = window.document.getElementById(anchorId)
				top = element.offsetTop
				window.scrollTo left: 0, top: top, behavior: 'smooth'
		criterionVisibilityChanged: (visible, id) ->
			@$emit('criterion-visibility-change', {visible, id})
