export default
	props:
		modelValue: default: ''
		elementName: type: String, default: ''
		error: type: Array
		labelName: type: String, default: ''
		labelClass: type: Object
		inputClass: type: Object
		list: type: String
		disabled: type: Boolean, default: false
	computed:
		_labelName: -> @labelName or @$t('activerecord.attributes.answer.' + @elementName)
		_labelClass: -> @labelClass or { 'label-error': @error }
		_inputClass: -> @inputClass or { 'input-error': @error }
