export default
	props:
		modelValue: default: ''
		elementName: type: String, default: ''
		options: type: Array, default: []
		error: type: Array
		labelName: type: String, default: ''
		labelClass: type: Object
		selectClass: type: Object
	computed:
		_labelName: -> @labelName or @$t('activerecord.attributes.answer.' + @elementName)
		_labelClass: -> @labelClass or { 'label-error': @error }
		_selectClass: -> @selectClass or { 'input-error': @error }
