import {assessments as config} from '../../data/config.coffee'
import AssessmentContent from './AssessmentContent.vue'
import Sidebar from './Sidebar.vue'
import FlashMessages from '../shared/FlashMessages.vue'
import AutocompletionDatalists from './AutocompletionDatalists.vue'
import {ListLoader} from 'vue-content-loader'
import _ from 'lodash'
import MetricsHelper from './mixins/MetricsHelper.coffee'

initialState = ->
	chapter: null
	subchapter: null
	weights:
		1: on, 2: on, 3: on
	compliances:
		yes: on, no: on, NA: on, undef: on
	approvals:
		approved: on, unapproved: on
	hierarchies:
		criteriaOnly: off
		requirementsOnly: off
		tableOfContent: off
	lookups:
		businessFunction: ''
		responsibility: ''
	answerContents:
		naApproved: off
		naUnapproved: off
		targetDate: off
		completionDate: off
		actionPlan: off
		incomplete: off
		rejected: off
		withFeedback: off
		withoutFeedback: off
		withActionPlan: off
	appearances:
		colorize: off, state: off, allAnswers: off
	compareVisibility:
		all: on
	compareHierarchy:
		criteriaOnly: off
		requirementsOnly: off
		tableOfContent: off

export default
	mixins: [MetricsHelper]
	components: { AssessmentContent, Sidebar, FlashMessages, AutocompletionDatalists, ListLoader }

	data: ->
		assessment: {}
		targetAssessment: {}
		targetAnswers: {}
		filters: {}
		loadingError: {}
		loading: true
		sidebarLoading: true
		hiddenCriteria: {}
		visibleCriteria: []
		visibleAnswers: []
		hiddenChapters: []
		hiddenSubchapters: []
		hiddenRequirements: []
		isCompareMode: false

	computed:
		subchapters: -> _.flatten(@assessment?.chapters?.map((chapter) -> chapter.subchapters))
		requirements: -> _.flatten(@subchapters.map((subchapter) -> subchapter.requirements))
		criteria: -> _.flatten(@requirements.map((requirement) -> requirement.criteria))
		targetExists: -> Object.keys(@targetAssessment).length isnt 0 and @targetAssessment.answers?

	mounted: ->
		@filters = initialState()

	created: ->
		await @loadAssessment()
		$(@$el).foundation()
		$(window).trigger('load.zf.sticky')

	watch:
		filters:
			handler: () ->
				return if @sidebarLoading
				@$nextTick -> # need nextTick for including change of visibility-change-event
					@refreshVisibilityTree()
					@refreshMetrics()
			deep: true
		'assessment.answers_in_profile':
			handler: () ->
				return if @sidebarLoading
				@refreshMetrics()
			deep: true

	methods:
		assignCompareMode: (value) ->
			@isCompareMode = value
		loadAssessment: ->
			assessmentId = window.location.pathname.split('/')[2]
			# load assessment
			try
				response = await @$http.get(config.endpoint + assessmentId)
			catch error
				@loadingError = error.response?.data?.flash
				return
			finally
				@loading = false
			assessment =
				infos: response.data
				chapters: []
				answers_in_profile: null
			assessment.infos.flash = {} unless 'flash' of assessment.infos
			@assessment = assessment

			# load catalog
			await @load config.endpoint + assessmentId + '/catalog', (r) => @assessment.chapters = r.data
			# load answers
			await @load config.endpoint + assessmentId + '/answers', (r) => @assessment.answers_in_profile = r.data
			@sidebarLoading = false
			@refreshMetrics()

		load: (path, success) ->
			@$http.get(path)
				.then success
				.catch (r) => @assessment.infos.flash = r.response?.data?.flash

		resetFilters: ->
			if @isCompareMode
				@targetAssessment = {}
				@targetAnswers = {}
			else
				@filters = initialState()

		refreshVisibilityTree: ->
			@hiddenRequirements = @requirements
				.filter((r) => _.every(r.criteria, (criterion) => @hiddenCriteria[criterion.id]))
				.map((r) -> r.id)
			@hiddenSubchapters = @subchapters
				.filter((s) => _.every(s.requirements, (s) => @hiddenRequirements.includes(s.id)))
				.map((s) -> s.id)
			@hiddenChapters = @assessment.chapters
				.filter((c) => _.every(c.subchapters, (c) => @hiddenSubchapters.includes(c.id)))
				.map((c) -> c.id)

		refreshMetrics: () ->
			@updateMetrics(@assessment.answers_in_profile, @criteria, @hiddenCriteria)

		criterionVisibilityChanged: ({visible, id}) ->
			@hiddenCriteria[id] = not visible

		updateTarget: (target) ->
			@targetAssessment = Object.assign({}, @targetAssessment, target)
			@isCompareMode = true
			for answer in @targetAssessment.answers
				@targetAnswers[answer.criterion_uuid] = answer

		setCompareFilterAll: (value) ->
			@filters.compareVisibility.all = value
