import ProgressBar from './ProgressBar.vue'
import _ from 'lodash'

export default
	components: { ProgressBar }
	props:
		assessmentInfos: Object
		metrics: Object
	data: ->
		isDevelop: @$store.state.isDevelop
	computed:
		assessmentStatusText: -> @$t('activerecord.attributes.assessment.status/' + @assessmentInfos.status)
		workflowPolicyText: -> @$t('enumerize.workflow_policy.' + @assessmentInfos.company.workflow_policy)
		questionsFullyAnswered: -> @assessmentInfos.ok.questions_fully_answered
		allAnswersAccepted: -> @assessmentInfos.ok.all_answers_accepted
		ok: ->  @questionsFullyAnswered and @allAnswersAccepted
		steps: ->
			if @assessmentInfos.workflow_log_data
				workflow_log = JSON.parse(@assessmentInfos.workflow_log_data)
				if workflow_log.length > 0
					workflow_log
				else
					['none']
		pastInitialSurvey: ->
			status = ['in_review', 'reviewed', 'archived']
			status.includes(@assessmentInfos.status) or @assessmentInfos.revision > 0
		bars: ->
			return {} unless @metrics?.values
			values = _.cloneDeep(@metrics?.values)
			if not @isDevelop
				delete values['point_score']
			if not @pastInitialSurvey
				delete values['rejected']
			values
	methods:
		workflow_log_entry: (step) ->
			@$t('assessment.workflow.log.' + step)
		clearWorkflow: ->
			@assessmentInfos.workflow_log_data = '[]'
			@steps = ['none']
			@assessmentInfos.revision = 0
			if @assessmentInfos.partner.role is 'customer'
				@assessmentInfos.status = 'new'
			else
				@assessmentInfos.status = 'completed'


