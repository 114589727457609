import MmogRequiredAbbr from './MmogRequiredAbbr.vue'

export default
	components: { MmogRequiredAbbr }
	props:
		modelValue: type: String, default: ''
		elementName: type: String, default: ''
		error: type: Array
		labelName: type: String, default: ''
		labelClass: type: Object
		textAreaClass: type: Object
		required: type: Boolean
	computed:
		_labelName: -> @labelName or @$t('activerecord.attributes.answer.' + @elementName)
		_labelClass: -> @labelClass or { 'label-error': @error }
		_textAreaClass: -> @textAreaClass or { 'input-error': @error }
