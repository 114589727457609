export default
	props:
		sourceAnswer: Object
		targetAnswer: Object
	data: ->
		answerAttributes: ['compliance', 'current_state', 'completion_date', 'gap', 'action_plan', 'target_date',
			'business_function', 'cost', 'cost_currency', 'responsibility', 'approval_date', 'customer_contact', 'approved',
			'customer_response']
		translatedBooleanAttributes: ['approved']

	methods:
		hasValue: (val) ->
			return val isnt undefined and val isnt null and val isnt ''

		labelForAttribute: (val, attr) ->
			if (attr in @translatedBooleanAttributes and val isnt null and val isnt undefined )
				@$t("assessments.sidebar.diff.attributes.#{val}")
			else
				val
